body {
  background-color: #282c34 !important;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}


.carousel-item, .carousel-item > img {
  height: 85vh;
  width: 100vw;
  object-fit:cover;
  background-position: center;
}


.navbar-toggler{
  background: white !important;
}

.warning{
  color: red;
  font-size: 1.5rem;
  font-weight: bold;
}

.info{
  color: rgb(16, 86, 143);
  font-size: 1.2rem;
  font-weight: bold;
}

.mobile-only {
  display: none;
}

/* on mobile devices */
@media (max-width: 768px) {
  .carousel-item {
    height: 400px;
  }
  .desktop-only {
    display: none;
  }
  .mobile-only {
    display: block;
  }
}

.row{
  width: 100%;
}


.nav-item {
  margin-right: 10px;
}

.nav-item > a {
  color: white;
  text-decoration: none;
  font-size: 1.2rem;
  margin-right: 2rem;
}

.nav-item > a:hover {
  color: #EBAC78;
}

.nav-item > a.active {
  color: #EBAC78;
}

footer  a {
  color: #232323;
  text-decoration: none;
  font-size: 1.2rem;
  margin-right: 2rem;
}

.thrown{
  height: 200px;
  position: absolute;
  top: 180px;
  z-index: 1;
}

#age{
  -wap-input-format: 'N'
}

canvas{
  border: 1px solid #232323;
}

.cover {
  /* make img tags fit cover */
  width: 100%;
  height: 400px;
  object-fit: cover;
}

/* should be part of bootstrap */

.padding-xs { padding: .25em; }
.padding-sm { padding: .5em; }
.padding-md { padding: 1em; }
.padding-lg { padding: 1.5em; }
.padding-xl { padding: 3em; }

.padding-x-xs { padding: .25em 0; }
.padding-x-sm { padding: .5em 0; }
.padding-x-md { padding: 1em 0; }
.padding-x-lg { padding: 1.5em 0; }
.padding-x-xl { padding: 3em 0; }

.padding-y-xs { padding: 0 .25em; }
.padding-y-sm { padding: 0 .5em; }
.padding-y-md { padding: 0 1em; }
.padding-y-lg { padding: 0 1.5em; }
.padding-y-xl { padding: 0 3em; }

.padding-top-xs { padding-top: .25em; }
.padding-top-sm { padding-top: .5em; }
.padding-top-md { padding-top: 1em; }
.padding-top-lg { padding-top: 1.5em; }
.padding-top-xl { padding-top: 3em; }

.padding-right-xs { padding-right: .25em; }
.padding-right-sm { padding-right: .5em; }
.padding-right-md { padding-right: 1em; }
.padding-right-lg { padding-right: 1.5em; }
.padding-right-xl { padding-right: 3em; }

.padding-bottom-xs { padding-bottom: .25em; }
.padding-bottom-sm { padding-bottom: .5em; }
.padding-bottom-md { padding-bottom: 1em; }
.padding-bottom-lg { padding-bottom: 1.5em; }
.padding-bottom-xl { padding-bottom: 3em; }

.padding-left-xs { padding-left: .25em; }
.padding-left-sm { padding-left: .5em; }
.padding-left-md { padding-left: 1em; }
.padding-left-lg { padding-left: 1.5em; }
.padding-left-xl { padding-left: 3em; }

.margin-xs { margin: .25em; }
.margin-sm { margin: .5em; }
.margin-md { margin: 1em; }
.margin-lg { margin: 1.5em; }
.margin-xl { margin: 3em; }

.margin-x-xs { margin: .25em 0; }
.margin-x-sm { margin: .5em 0; }
.margin-x-md { margin: 1em 0; }
.margin-x-lg { margin: 1.5em 0; }
.margin-x-xl { margin: 3em 0; }

.margin-y-xs { margin: 0 .25em; }
.margin-y-sm { margin: 0 .5em; }
.margin-y-md { margin: 0 1em; }
.margin-y-lg { margin: 0 1.5em; }
.margin-y-xl { margin: 0 3em; }

.margin-top-xs { margin-top: .25em; }
.margin-top-sm { margin-top: .5em; }
.margin-top-md { margin-top: 1em; }
.margin-top-lg { margin-top: 1.5em; }
.margin-top-xl { margin-top: 3em; }

.margin-right-xs { margin-right: .25em; }
.margin-right-sm { margin-right: .5em; }
.margin-right-md { margin-right: 1em; }
.margin-right-lg { margin-right: 1.5em; }
.margin-right-xl { margin-right: 3em; }

.margin-bottom-xs { margin-bottom: .25em; }
.margin-bottom-sm { margin-bottom: .5em; }
.margin-bottom-md { margin-bottom: 1em; }
.margin-bottom-lg { margin-bottom: 1.5em; }
.margin-bottom-xl { margin-bottom: 3em; }

.margin-left-xs { margin-left: .25em; }
.margin-left-sm { margin-left: .5em; }
.margin-left-md { margin-left: 1em; }
.margin-left-lg { margin-left: 1.5em; }
.margin-left-xl { margin-left: 3em; }

.Toastify__toast-container--top-right {
  top: 50px !important;
}
